/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import * as S from "~sanity/sections";
import { Button, Layout, SEO } from "~components";

const ManifestPage = () => (
  <>
    <SEO noIndex />

    <Layout>
      <S.IntroductionSection
        data={{
          backgroundColor: { hex: `#16396D`, title: `Light Blue` },
          fontColor: { hex: `#ffffff`, title: `White` },
          heading: `Manifest`,
          body: `Manifest`
        }}
      />

      <S.ImageArticleSection
        data={{
          subheading: `The Fertility and Research Centre`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
          image: `/images/placeholder/image1.jpg`,
          imagePosition: `left`,
          headingPosition: `above`
        }}
      />

      <S.ColumnTextSection
        data={{
          backgroundColor: { hex: `#f5f6fa`, title: `Gray` },
          fontColor: { hex: `#000000`, title: `black` },
          subheading: `Our history`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          leftBody: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
          rightBody: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
        }}
      />

      <S.ImageArticleSection
        data={{
          subheading: `Our Values`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
          image: `/images/placeholder/image3.jpg`,
          imagePosition: `right`,
          headingPosition: `inline`
        }}
      />

      <S.Column3Section
        data={{
          backgroundColor: { hex: `#0A77C1`, title: `Light Blue` },
          fontColor: { hex: `#ffffff`, title: `White` },
          columnLeft: {
            heading: `Value 1`,
            body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
          },
          columnMiddle: {
            heading: `Value 2`,
            body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
          },
          columnRight: {
            heading: `Value 3`,
            body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
          }
        }}
      />

      <S.TextGridButtonSection
        data={{
          subheading: `Research Center`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
          content: [
            {
              heading: `New approaches`,
              body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
            },
            {
              heading: `In vitro maturation (IVM)`,
              body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
            },
            {
              heading: `Novel stimulation`,
              body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
            },
            {
              heading: `Novel anti-oxidants`,
              body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`
            }
          ]
        }}
      />

      <S.ImageArticleSection
        data={{
          backgroundColor: { hex: `#f5f6fa`, title: `Gray` },
          fontColor: { hex: `#000000`, title: `black` },
          subheading: `Teaching Hospital`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
          image: `/images/placeholder/image4.jpg`,
          imagePosition: `left`,
          headingPosition: `inline`
        }}
      />

      <S.ArticleCTASection
        data={{
          subheading: `News and Events`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.`,
          articles: [
            {
              title: `Lorem ipsul dolor sit amet`,
              preview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.`,
              link: `/`,
              tag: `News`
            },
            {
              title: `Lorem ipsul dolor sit amet`,
              preview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.`,
              link: `/`,
              tag: `Events`
            },
            {
              title: `Lorem ipsul dolor sit amet`,
              preview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.`,
              link: `/`,
              tag: `Events`
            }
          ],
          link: `/news-and-events`
        }}
      />

      <S.GetStartedSection
        data={{
          subheading: `Start your journey now`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
          image: `/images/placeholder/image2.png`
        }}
      />

      <S.ExploreMoreSection
        data={{
          backgroundColor: { hex: `#f5f6fa`, title: `Gray` },
          fontColor: { hex: `#000000`, title: `black` },
          heading: `Explore more`,
          button1: { label: `Our team`, target: `/our-team` },
          button2: { label: `Our services`, target: `/our-services` }
        }}
      />

      <S.BioGridSection
        data={{
          subheading: `Specialist team`,
          heading: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          bios: [
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            },
            {
              name: `Name`,
              surname: `Surname`,
              qualifications: `MBBS MMed (RH&HG) FRANZCOG CREI`,
              specialisation: `Gynaecology, Endometreosis and PCOS`,
              header: `Professor William Ledger is Director of the Department of Reproductive Medicine. He is also Head and Professor of Obstetrics & Gynaecology, School of Women's & Children's Health at the Royal Hospital for Women in Sydney and Head of the Discipline of Obstetrics and Gynaecology at the University of New South Wales.`,
              body: `Professor Ledger moved to Sydney in 2011 having been Professor of Obstetrics and Gynaecology at the University of Sheffield since 1999. Before that he was Reader in Obstetrics and Gynaecology at the University of Oxford and Clinical Lecturer and Subspecialist Trainee in Reproductive Medicine in Edinburgh. He was a Member of the UK Human Fertilisation and Embryology Authority for five years before his move to Australia, and also was Chair of the Royal College of Obstetricians and Gynaecologists Examinations and Assessment Committee for five years to 2010, and a Member of Council of the RCOG. His research interests focus on in vitro fertilisation and assisted reproduction, impacts of reproductive disorders such as gynaecological cancer, endometriosis and polycystic ovary syndrome on quality of life, reproductive effects of cancer treatment and health economic and demographic aspects of infertility. He is married, with three sons. `,
              image: `images/placeholder/bio.jpg`
            }
          ]
        }}
      />
    </Layout>
  </>
);

export default ManifestPage;
